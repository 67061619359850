import React, { useState } from "react";

import { Textfit } from 'react-textfit';

const TextFit = (props) => {
    const [isResized, setIsResized] = useState(false)

    if (typeof window !== "undefined") {
        return (
            <Textfit mode="single" forceSingleModeWidth={true} max={300} onReady={() => {
                if (!isResized) {
                    window.dispatchEvent(new Event('resize'));
                    setIsResized(true)
                }
            }}>
                {props.text}
            </Textfit>
        )
    } else {
        return props.text
    }
}

export default TextFit;
